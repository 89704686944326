import React from 'react';

function Blog() {
  return (
    <div>
      <h1>Mushfiq's Personal Blog</h1>
      <p>Writing excerpts, a dying art form in this day and age, will appear here.</p>
    </div>
  );
}

export default Blog;