// src/data/images.js

const images = [
  {
    id: 1,
    alt: '2021-09-24_IMG_1545',
    thumbnail: require('../../assets/images/thumbs/2021-09-24_IMG_1545.jpg'),
    medium: require('../../assets/images/medium/2021-09-24_IMG_1545.jpg'),
    full: require('../../assets/images/full/2021-09-24_IMG_1545.jpg'),
  },
  {
    id: 2,
    alt: '2022-11-06_IMG_0503',
    thumbnail: require('../../assets/images/thumbs/2022-11-06_IMG_0503.jpeg'),
    medium: require('../../assets/images/medium/2022-11-06_IMG_0503.jpeg'),
    full: require('../../assets/images/full/2022-11-06_IMG_0503.jpeg'),
  },
  {
    id: 3,
    alt: '2023-06-10_IMG_3130',
    thumbnail: require('../../assets/images/thumbs/2023-06-10_IMG_3130.jpg'),
    medium: require('../../assets/images/medium/2023-06-10_IMG_3130.jpg'),
    full: require('../../assets/images/full/2023-06-10_IMG_3130.jpg'),
  },
  {
    id: 4,
    alt: '2023-06-12_IMG_3222',
    thumbnail: require('../../assets/images/thumbs/2023-06-12_IMG_3222.jpg'),
    medium: require('../../assets/images/medium/2023-06-12_IMG_3222.jpg'),
    full: require('../../assets/images/full/2023-06-12_IMG_3222.jpg'),
  },
  {
    id: 5,
    alt: '2023-06-13_IMG_3324',
    thumbnail: require('../../assets/images/thumbs/2023-06-13_IMG_3324.jpg'),
    medium: require('../../assets/images/medium/2023-06-13_IMG_3324.jpg'),
    full: require('../../assets/images/full/2023-06-13_IMG_3324.jpg'),
  },
  {
    id: 6,
    alt: '2023-06-25_dji_fly_20230625_132556_256_1687715202063_pano',
    thumbnail: require('../../assets/images/thumbs/2023-06-25_dji_fly_20230625_132556_256_1687715202063_pano.jpg'),
    medium: require('../../assets/images/medium/2023-06-25_dji_fly_20230625_132556_256_1687715202063_pano.jpg'),
    full: require('../../assets/images/full/2023-06-25_dji_fly_20230625_132556_256_1687715202063_pano.jpg'),
  },
  {
    id: 7,
    alt: '2023-06-25_dji_fly_20230625_133102_272_1687724287959_pano',
    thumbnail: require('../../assets/images/thumbs/2023-06-25_dji_fly_20230625_133102_272_1687724287959_pano.jpg'),
    medium: require('../../assets/images/medium/2023-06-25_dji_fly_20230625_133102_272_1687724287959_pano.jpg'),
    full: require('../../assets/images/full/2023-06-25_dji_fly_20230625_133102_272_1687724287959_pano.jpg'),
  },
  {
    id: 8,
    alt: '2023-06-25_dji_fly_20230625_134148_276_1687715086509_pano',
    thumbnail: require('../../assets/images/thumbs/2023-06-25_dji_fly_20230625_134148_276_1687715086509_pano.jpg'),
    medium: require('../../assets/images/medium/2023-06-25_dji_fly_20230625_134148_276_1687715086509_pano.jpg'),
    full: require('../../assets/images/full/2023-06-25_dji_fly_20230625_134148_276_1687715086509_pano.jpg'),
  },
  {
    id: 9,
    alt: '2023-06-25_dji_fly_20230625_160346_279_1687724283121_pano',
    thumbnail: require('../../assets/images/thumbs/2023-06-25_dji_fly_20230625_160346_279_1687724283121_pano.jpg'),
    medium: require('../../assets/images/medium/2023-06-25_dji_fly_20230625_160346_279_1687724283121_pano.jpg'),
    full: require('../../assets/images/full/2023-06-25_dji_fly_20230625_160346_279_1687724283121_pano.jpg'),
  },
  {
    id: 10,
    alt: '2023-07-02_dji_fly_20230702_185808_329_1688338769317_pano',
    thumbnail: require('../../assets/images/thumbs/2023-07-02_dji_fly_20230702_185808_329_1688338769317_pano.jpg'),
    medium: require('../../assets/images/medium/2023-07-02_dji_fly_20230702_185808_329_1688338769317_pano.jpg'),
    full: require('../../assets/images/full/2023-07-02_dji_fly_20230702_185808_329_1688338769317_pano.jpg'),
  },
  {
    id: 11,
    alt: '2023-07-03_dji_fly_20230703_163258_350_1688416613716_pano',
    thumbnail: require('../../assets/images/thumbs/2023-07-03_dji_fly_20230703_163258_350_1688416613716_pano.jpg'),
    medium: require('../../assets/images/medium/2023-07-03_dji_fly_20230703_163258_350_1688416613716_pano.jpg'),
    full: require('../../assets/images/full/2023-07-03_dji_fly_20230703_163258_350_1688416613716_pano.jpg'),
  },
  {
    id: 12,
    alt: '2023-07-05_dji_fly_20230705_054010_376_1688550129850_pano',
    thumbnail: require('../../assets/images/thumbs/2023-07-05_dji_fly_20230705_054010_376_1688550129850_pano.jpg'),
    medium: require('../../assets/images/medium/2023-07-05_dji_fly_20230705_054010_376_1688550129850_pano.jpg'),
    full: require('../../assets/images/full/2023-07-05_dji_fly_20230705_054010_376_1688550129850_pano.jpg'),
  },
  {
    id: 13,
    alt: '2023-08-18_dji_fly_20230818_193818_415_1692402371724_pano',
    thumbnail: require('../../assets/images/thumbs/2023-08-18_dji_fly_20230818_193818_415_1692402371724_pano.jpg'),
    medium: require('../../assets/images/medium/2023-08-18_dji_fly_20230818_193818_415_1692402371724_pano.jpg'),
    full: require('../../assets/images/full/2023-08-18_dji_fly_20230818_193818_415_1692402371724_pano.jpg'),
  },
  {
    id: 14,
    alt: '2024-01-05_IMG_5291',
    thumbnail: require('../../assets/images/thumbs/2024-01-05_IMG_5291.jpeg'),
    medium: require('../../assets/images/medium/2024-01-05_IMG_5291.jpeg'),
    full: require('../../assets/images/full/2024-01-05_IMG_5291.jpeg'),
  },
  {
    id: 15,
    alt: '2024-04-19_Eye_of_the_Storm',
    thumbnail: require('../../assets/images/thumbs/2024-04-19_Eye_of_the_Storm.jpg'),
    medium: require('../../assets/images/medium/2024-04-19_Eye_of_the_Storm.jpg'),
    full: require('../../assets/images/full/2024-04-19_Eye_of_the_Storm.jpg'),
  },
  {
    id: 16,
    alt: '2024-06-01_Gooseberry_Astro Water_Bottle',
    thumbnail: require('../../assets/images/thumbs/2024-06-01_Gooseberry_Astro_Water_Bottle.jpg'),
    medium: require('../../assets/images/medium/2024-06-01_Gooseberry_Astro_Water_Bottle.jpg'),
    full: require('../../assets/images/full/2024-06-01_Gooseberry_Astro_Water_Bottle.jpg'),
  },
  {
    id: 17,
    alt: '2024-08-21_Raptor Show-2',
    thumbnail: require('../../assets/images/thumbs/2024-08-21_Raptor_Show-2.jpg'),
    medium: require('../../assets/images/medium/2024-08-21_Raptor_Show-2.jpg'),
    full: require('../../assets/images/full/2024-08-21_Raptor_Show-2.jpg'),
  },
  {
    id: 18,
    alt: '2024-08-23_dji_fly_20240823_073134_738_1724423516851_pano',
    thumbnail: require('../../assets/images/thumbs/2024-08-23_dji_fly_20240823_073134_738_1724423516851_pano.jpg'),
    medium: require('../../assets/images/medium/2024-08-23_dji_fly_20240823_073134_738_1724423516851_pano.jpg'),
    full: require('../../assets/images/full/2024-08-23_dji_fly_20240823_073134_738_1724423516851_pano.jpg'),
  },
  {
    id: 19,
    alt: '2024-08-30_IMG_7474',
    thumbnail: require('../../assets/images/thumbs/2024-08-30_IMG_7474.jpeg'),
    medium: require('../../assets/images/medium/2024-08-30_IMG_7474.jpeg'),
    full: require('../../assets/images/full/2024-08-30_IMG_7474.jpeg'),
  },
  {
    id: 20,
    alt: '2024-08-31_dji_fly_20240831_105138_889_1725126724928_pano',
    thumbnail: require('../../assets/images/thumbs/2024-08-31_dji_fly_20240831_105138_889_1725126724928_pano.jpg'),
    medium: require('../../assets/images/medium/2024-08-31_dji_fly_20240831_105138_889_1725126724928_pano.jpg'),
    full: require('../../assets/images/full/2024-08-31_dji_fly_20240831_105138_889_1725126724928_pano.jpg'),
  },
  {
    id: 21,
    alt: '2024-08-31_dji_fly_20240831_134428_893_1725137124572_pano',
    thumbnail: require('../../assets/images/thumbs/2024-08-31_dji_fly_20240831_134428_893_1725137124572_pano.jpg'),
    medium: require('../../assets/images/medium/2024-08-31_dji_fly_20240831_134428_893_1725137124572_pano.jpg'),
    full: require('../../assets/images/full/2024-08-31_dji_fly_20240831_134428_893_1725137124572_pano.jpg'),
  },
  {
    id: 22,
    alt: '2024-08-31_dji_fly_20240831_153740_904_1725144151993_pano',
    thumbnail: require('../../assets/images/thumbs/2024-08-31_dji_fly_20240831_153740_904_1725144151993_pano.jpg'),
    medium: require('../../assets/images/medium/2024-08-31_dji_fly_20240831_153740_904_1725144151993_pano.jpg'),
    full: require('../../assets/images/full/2024-08-31_dji_fly_20240831_153740_904_1725144151993_pano.jpg'),
  },
  {
    id: 23,
    alt: '2024-09-01_IMG_7588',
    thumbnail: require('../../assets/images/thumbs/2024-09-01_IMG_7588.jpeg'),
    medium: require('../../assets/images/medium/2024-09-01_IMG_7588.jpeg'),
    full: require('../../assets/images/full/2024-09-01_IMG_7588.jpeg'),
  },
  {
    id: 24,
    alt: '2024-09-01_IMG_7653',
    thumbnail: require('../../assets/images/thumbs/2024-09-01_IMG_7653.jpeg'),
    medium: require('../../assets/images/medium/2024-09-01_IMG_7653.jpeg'),
    full: require('../../assets/images/full/2024-09-01_IMG_7653.jpeg'),
  },
  {
    id: 25,
    alt: '2024-09-02_dji_fly_20240902_123038_958_1725305879117_pano',
    thumbnail: require('../../assets/images/thumbs/2024-09-02_dji_fly_20240902_123038_958_1725305879117_pano.jpg'),
    medium: require('../../assets/images/medium/2024-09-02_dji_fly_20240902_123038_958_1725305879117_pano.jpg'),
    full: require('../../assets/images/full/2024-09-02_dji_fly_20240902_123038_958_1725305879117_pano.jpg'),
  },
  {
    id: 26,
    alt: '2024-09-04_IMG_7676',
    thumbnail: require('../../assets/images/thumbs/2024-09-04_IMG_7676.jpeg'),
    medium: require('../../assets/images/medium/2024-09-04_IMG_7676.jpeg'),
    full: require('../../assets/images/full/2024-09-04_IMG_7676.jpeg'),
  },
  {
    id: 27,
    alt: '2024-09-06_IMG_7779',
    thumbnail: require('../../assets/images/thumbs/2024-09-06_IMG_7779.jpeg'),
    medium: require('../../assets/images/medium/2024-09-06_IMG_7779.jpeg'),
    full: require('../../assets/images/full/2024-09-06_IMG_7779.jpeg'),
  },
  {
    id: 28,
    alt: '2024-09-06_dji_fly_20240906_194136_965_1725673515773_pano',
    thumbnail: require('../../assets/images/thumbs/2024-09-06_dji_fly_20240906_194136_965_1725673515773_pano.jpg'),
    medium: require('../../assets/images/medium/2024-09-06_dji_fly_20240906_194136_965_1725673515773_pano.jpg'),
    full: require('../../assets/images/full/2024-09-06_dji_fly_20240906_194136_965_1725673515773_pano.jpg'),
  },
  {
    id: 29,
    alt: '2024-09-06_dji_fly_20240906_194438_967_1725673506093_pano',
    thumbnail: require('../../assets/images/thumbs/2024-09-06_dji_fly_20240906_194438_967_1725673506093_pano.jpg'),
    medium: require('../../assets/images/medium/2024-09-06_dji_fly_20240906_194438_967_1725673506093_pano.jpg'),
    full: require('../../assets/images/full/2024-09-06_dji_fly_20240906_194438_967_1725673506093_pano.jpg'),
  },
];

export default images;